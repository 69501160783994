import * as React from "react"
import { navigate } from "@reach/router"
import { Typography, Grid, Button } from "@material-ui/core"

import blueSmallTriangleImg from "../../../../media/blue-small-triangle.png"
import { securityList, learnMoreList } from "./data"
import { useStyles } from "./styles"

const Security = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.innerWrapper}>
                <Grid container spacing={10} className={classes.listWrapper}>
                    {securityList.map((item, index) => (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
                              key={`technology-content-${index}`}
                              className={classes.item}
                        >
                            <div className={classes.itemInnerWrapper}>
                                <img src={blueSmallTriangleImg} className={classes.itemTagImage} />
                                <Typography variant="subtitle2" className={classes.itemTitle}>
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" className={classes.itemDescription}>
                                    {item.description}
                                </Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={10} className={classes.learnMoreSection}>
                    {learnMoreList.map((item, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
                              key={`learn-more-${index}`}
                        >
                            <div className={classes.learnMoreItemContainer}>
                                <img src={item.backgroundImage} className={classes.learnMoreItemImage} />
                                <div className={classes.learnMoreItemInnerWrapper}>
                                    <Typography variant="subtitle2" className={classes.learnMoreTitle}>
                                        {item.title}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate("/report-issue")}
                                        className={classes.reportButton}
                                    >
                                        Report an Issue
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

export default Security

