import * as React from "react"
import { Link } from "@reach/router"
import { Typography } from "@material-ui/core"

import { useStyles } from "./styles"

interface NextServiceProps {
  text: string,
  link: string,
}

const NextService = ({ text, link }: NextServiceProps) => {
  const classes = useStyles()

  return (
    <Link to={`${link}`} className={classes.root}>
      <Typography variant="body1" className={classes.navLinkTitle}>
        {`${text} >`}
      </Typography>
    </Link>
  )
}

export default NextService
