import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 60,
    textDecoration: "none",
    backgroundColor: "#2697db",
  },
  navLinkTitle: {
    color: "#fff",
  },
}))
