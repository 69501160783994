import { makeStyles } from "@material-ui/core/styles"

interface SubHeroProps {
  backgroundImage: string
}

export const useStyles = makeStyles((theme) => ({
  root: ({ backgroundImage }: SubHeroProps) => ({
    position: "relative",
    width: "100%",
    height: "30vw",
    minHeight: 200,
    maxHeight: 300,
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('sm')]: {
      maxHeight: "100%",
      backgroundColor: "#2d65b2",
      backgroundImage: "none"
    },
  }),
  heroImage: {
    width: "100%",
    minHeight: 400,
  },
  wrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  heroTitle: {
    color: "#fff",
    fontSize: "2.8rem",
    lineHeight: "3.4rem",
    maxWidth: 1440,
    textAlign: "center",
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      fontSize: "1.5rem",
    lineHeight: "2rem",
    textAlign: "left"
    },
  },
  heroDescription: {
    maxWidth: 1440,
    color: "#fff",
    lineHeight: 1.2,
    marginTop: 30,
    textAlign: "center",
  }
}))
