import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"

import SecurityCommitment from "../../sections/technology/SecurityCommitment";
import strategicAdvisoryBgUrl from "../../../media/strategic-advisory-banner-overlay.png"
import NextService from "../../sections/services/NextService";

const securityCommitmentTitle = 'Security Commitment'
const securityCommitmentDescription = ''

const SecurityCommitmentPage = ({ location }: PageProps) => (

    <Layout location={location}>
        <SEO title={securityCommitmentTitle}/>
        <SubHero
            backgroundImage={strategicAdvisoryBgUrl}
            title={securityCommitmentTitle}
            description={securityCommitmentDescription}
        />
        <SecurityCommitment/>
        <NextService
            text='Next, learn more about us'
            link='/about-us'/>
    </Layout>
)

export default SecurityCommitmentPage
