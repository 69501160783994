import clientProfileIcon from "../../../../media/case-study-banner-overlay.jpg"

export const securityList = [
    {
        title: "Penetration Testing",
        description: "We perform an independent third-party penetration test at least annually to ensure that the security posture of our services is uncompromised.",
    },
    {
        title: "Security Awareness Training",
        description: "Our team members are required to go through employee security awareness training covering industry standard practices and information security topics such as phishing and password management.",
    },
    {
        title: "Third-Party Audits",
        description: "Our organization undergoes independent third-party assessments to test our security controls.",
    },
    {
        title: "Roles and Responsibilities",
        description: "Roles and responsibilities related to our information security program and the protection of our customer’s data are well defined and documented.",
    },
    {
        title: "Information Security Program",
        description: "We have an information security program in place that is communicated throughout the organization. Our information security program follows the criteria set forth by SOC 2.",
    },
    {
        title: "Continuous Monitoring",
        description: "We continuously monitor our security and compliance status to ensure there are no lapses.",
    }
]

export const learnMoreList = [
    {
        title: "Found a potential issue? Please help us by reporting it so we can fix it quickly.",
        linkTitle: "Report Vulnerabilities",
        backgroundImage: clientProfileIcon
    }
]

