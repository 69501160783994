import * as React from "react"
import { Box, Typography } from "@material-ui/core"

import { useStyles } from "./styles"

interface SubHeroProps {
  backgroundImage: string,
  title: string,
  description: string  
}

const SubHero = ({ backgroundImage, title, description }: SubHeroProps) => {
  const classes = useStyles({ backgroundImage })

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <Typography variant="h1" className={classes.heroTitle}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.heroDescription}>
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

export default SubHero
